<template>
<div class="pa-2">
    <v-row justify="end">
        <v-col cols="12" sm="12" md="4" lg="6" class="text-left">
            <el-button v-if="params.filter && Object.keys(params.filter).length>0" type="plain" @click="clearFilter" color="error">
                <v-icon small>close</v-icon> Clear Filters
            </el-button>
        </v-col>
        <v-col cols="12" sm="12" md="8" lg="6">
            <el-input suffix-icon="el-icon-search" v-model="params.search" clearable placeholder="Search" @keyup.enter.native="getRoutes()">
                <template slot="prepend">
                    <el-button @click="createRoute()"><i class="el-icon-plus"></i> Create Route</el-button>
                </template>
            </el-input>
        </v-col>
    </v-row>
    <el-container>
        <el-main>
            <v-data-table :footer-props="{'items-per-page-options':[15, 30, 50, 100]}" :sort-by.sync="sortBy" :key="tabRefresh" :sort-desc.sync="sortDesc" dense :loading="loading" :items="data.data" :headers="headers">
                <template v-slot:item.action="{ item }">
                    <el-button plain @click="editRoute(item)" size="small" icon="el-icon-edit" circle></el-button>
                    <el-button size="small" type="danger" icon="el-icon-delete" circle @click="removeRoute(item)"></el-button>
                </template>
                <template v-slot:item.admin="{ item }">
                    <v-icon v-if="item.admin" color="success">check_circle_outline</v-icon>
                    <v-icon v-else color="red">cancel</v-icon>
                </template>
                 <template v-slot:item.systemAdmin="{ item }">
                    <v-icon v-if="item.systemAdmin" color="success">check_circle_outline</v-icon>
                    <v-icon v-else color="red">cancel</v-icon>
                </template>
                
                <template v-slot:item.protected="{ item }">
                    <v-icon v-if="item.protected" color="success">check_circle_outline</v-icon>
                    <v-icon v-else color="red">cancel</v-icon>
                </template>
                <template v-slot:item.isActive="{ item }">
                    <el-tag size="small" style="cursor: pointer" @click="changeActiveStatus(item)" :type="item.isActive ? 'primary' : 'danger'">{{item.isActive?'Active': 'Inactive'}}</el-tag>
                </template>
                <template v-slot:item.tabs="{ item }">
                    <el-tag size="mini" v-for="tab in item.routeTabs" :key="tab.id" closable @close="handleClose(item, tab)" :disable-transitions="false">{{tab.name}}</el-tag>
                    <el-input class="input-new-tag" @blur="unfocusField(item)" v-if="item.addTab" v-model="item.tabValue" :ref="'saveTagInput_'+item.id" size="mini" @keyup.enter.native="saveNewTab(item)">
                    </el-input>
                    <v-btn v-else class="pa-1 ma-2" icon small @click="addTab(item)">
                        <v-icon>add_circle_outline</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </el-main>
        <!-- <el-footer>
            <el-row justify="center" type="flex">
                <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="page" :page-size.sync="params.limit" :page-sizes="[10, 25, 50, 100]" layout="sizes, prev, pager, next, jumper" :total="data.total">
                </el-pagination>
            </el-row>
        </el-footer> -->
    </el-container>

    <el-dialog :visible.sync="modal" width="750px" :fullscreen="$vuetify.breakpoint.mobile" :title="item.modalType + ' Route'" :closed="clear">
        <el-form class="modal-form" :model="item" ref="form" @submit.native.prevent="saveItem">

            <el-form-item prop="name">
                <el-input v-model="item.name" placeholder="Name" clearable>
                    <v-icon v-if="item.name" slot="suffix" class="mr-1" small color="green">check</v-icon>
                    <v-icon v-else slot="suffix" class="mr-1" small color="orange">warning</v-icon>
                </el-input>
            </el-form-item>
            <el-form-item prop="path">
                <el-input v-model="item.path" placeholder="Path" clearable>
                    <v-icon v-if="item.path" slot="suffix" class="mr-1" small color="green">check</v-icon>
                    <v-icon v-else slot="suffix" class="mr-1" small color="orange">warning</v-icon>
                </el-input>
            </el-form-item>
            <el-form-item prop="component">
                <el-input v-model="item.component" placeholder="Component" clearable>
                    <v-icon v-if="item.component" slot="suffix" class="mr-1" small color="green">check</v-icon>
                    <v-icon v-else slot="suffix" class="mr-1" small color="orange">warning</v-icon>
                </el-input>
            </el-form-item>
            <el-form-item style="text-align: center">
                <el-row justify="center" type="flex">
                    <el-checkbox v-model="item.admin" label="Admin"></el-checkbox>
                    <el-checkbox v-model="item.systemAdmin" label="System Admin"></el-checkbox>
                    <el-checkbox v-model="item.protected" label="Protected"></el-checkbox>

                </el-row>
            </el-form-item>

            <el-form-item style="text-align: center">
                <el-button v-if="item.modalType=='Add'" :loading="loading" style="min-width: 40%" type="primary" :disabled="!item.name || !item.path || !item.component" native-type="submit" block> {{!loading?'Create Route':''}}</el-button>
                <el-button v-else :loading="loading" style="min-width: 40%" type="primary" :disabled="!item.name || !item.path || !item.component" native-type="submit" block> {{!loading?'Update Route':''}}</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</div>
</template>

<script>
export default {
    data: () => ({
        headers: [{
                text: 'Action',
                align: 'center',
                sortable: false,
                value: 'action'
            },
            {
                text: 'Name',
                value: 'name',
                align: 'center'
            },
            {
                text: 'Path',
                value: 'path',
                align: 'center'
            },
            {
                text: 'Admin',
                value: 'admin',
                align: 'center'
            },
            {
                text: 'System Admin',
                value: 'systemAdmin',
                align: 'center'
            },
            
            {
                text: 'Protected',
                value: 'protected',
                align: 'center'
            },
            {
                text: 'Status',
                value: 'isActive',
                align: 'center'
            },
            {
                text: 'Tabs',
                value: 'tabs',
                align: 'center'
            }
        ],
        loading: false,
        page: 1,
        params: {
            limit: 15,
            offset: 0,
            search: null
        },
        item: {},
        data: {
            count: 0,
            data: []
        },
        modal: false,
        sortDesc: false,
        sortBy: 'name',
        tabRefresh: 0
    }),
    watch: {
        async page(value) {
            this.params.offset = (value - 1) * this.params.limit
            await this.getRoutes()
        },
        'params.limit': {
            immediate: true,
            async handler() {
                await this.getRoutes()

            }
        },
        

    },
    async created() {
        await this.getRoutes()
        this.loading = false
    },
    methods: {
        addTab(route) {
            let index = this.data.data.indexOf(this.data.data.find(x => x.id == route.id))
            this.data.data[index].addTab = true
            this.tabRefresh++
            this.$nextTick(() => 
            setTimeout(()=>{
                this.$refs['saveTagInput_' + route.id].$refs.input.focus()
            }, 100))},
        unfocusField(route) {
            let index = this.data.data.indexOf(this.data.data.find(x => x.id == route.id))
            this.data.data[index].addTab = false
            this.tabRefresh++
            this.$nextTick(() => {
                this.$refs['saveTagInput_' + route.id].$refs.input.unfocus();
            });
        },
        async changeActiveStatus(item) {
            item.isActive = !item.isActive
            await this.$API.updateRoute({
                id: item.id,
                isActive: item.isActive
            })
            this.$message({
                type: 'success',
                message: 'Successfully updated!'
            });
        },
        clearFilter() {
            this.$refs.filterTable.clearFilter();
            this.params.filter = {}
            this.getRoutes()
        },
        createRoute() {
            this.item = {
                modalType: 'Add',
                admin: false,
                protected: true
            }
            this.modal = true
        },
        editRoute(org) {
            this.item = org
            this.item.modalType = 'Edit'

            this.modal = true
        },
        filterChange(data) {
            this.params.filter = data
            this.getRoutes()
        },
        async getRoutes() {
            this.loading = true

            this.data = await this.$API.getRoutes({
                params: this.params
            })
            this.loading = false
        },
        async handleClose(route, tab) {
            let index = this.data.data.indexOf(this.data.data.find(x => x.id == route.id))
            let tabIndex = this.data.data[index].routeTabs.indexOf(this.data.data[index].routeTabs.find(x => x.id == tab.id))
            await this.$API.updateRouteTab({
                id: tab.id,
                isActive: false,
                isDeleted: true
            })
            this.data.data[index].routeTabs.splice(tabIndex, 1);
            this.tabRefresh++

        },
        removeRoute(org) {
            console.log(org)
            this.$confirm('Are you sure you want to delete this Route?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                await this.$API.updateRoute({
                    id: org.id,
                    isActive: false,
                    isDeleted: true
                })
                this.getRoutes()
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        async saveItem() {
            this.loading = true
            if (this.item.id) {
                await this.$API.updateRoute(this.item)
                this.$message.success('Update successful!')

            } else {
                await this.$API.createRoute(this.item)
                this.$message.success('Successfully created route!')
                this.getRoutes()
            }
            this.loading = false
            this.item = {}
            this.modal = false
        },
        async saveNewTab(route) {
            let result = await this.$API.createRouteTab({
                routeId: route.id,
                name: route.tabValue
            })
            route.routeTabs.push(result)
            route.addTab = false
            route.tabValue = null

            this.tabRefresh++
        },
        clear() {
            this.loading = false
            this.item = {}
            this.modal = false
        }
    }
}
</script>

<style scoped>
.modal-form {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 10px;
}

.el-tag+.el-tag {
    margin-left: 10px;
    margin-top: 5px
}

.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.input-new-tag {
    width: 90px;
    margin-left: 10px;
    margin-top: 10px;

    vertical-align: bottom;
}
</style>
